

import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Header from './../Header/Header'
import Footer from './../Footer/Footer'
import Enquiryform from './Enquiryform'
export default class Seamlesspipes extends Component {
  componentDidMount() {
    document.title = "Patson Seamlesspipes"
  }
  render() {
    const openLink = (e, link) => {
      e.preventDefault();
      console.log(link);
      window.location = link
    }
    return (
      <React.Fragment>
        <Header />
        <div className="breadcrumb-area gradient-bg bg-cover shadow dark text-light text-center" style={{ backgroundImage: 'url(assets/img/blog/tubes.jpg)' }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <h1>Products</h1>
                <ul className="breadcrumb">
                  {/* <li><a href="index-2.html"><i className="fas fa-home" /> Home</a></li> */}
                  <li><NavLink to="/">Home</NavLink></li>
                  <li className="active">Cheese Tubes/Pipes</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrumb */}
        {/* Star Services Details Area
    ============================================= */}
        <div className="services-details-area default-padding">
          <div className="container">
            <div className="services-details-items">
              <div className="row">
                <div className="col-lg-4 services-sidebar order-last order-lg-first">
                  {/* Single Widget */}
                  <div className="single-widget services-list">
                    <div className="content">
                      <ul>
                        {/* <li className="current-item"><a href="services-details.html">Maintenance Service</a></li>
                        <li><a href="services-details.html">Agricultural Process</a></li>
                        <li><a href="services-details.html">Material Engineering</a></li>
                        <li><a href="services-details.html">Industry Construction</a></li>
                        <li><a href="services-details.html">Chemical Research</a></li> */}

                        <button>OUR PRODUCTS</button>
                        <li><a onClick={(e) => { openLink(e, '/seamlesspipes') }}>M.S. SEAMLESS PIPES</a></li>
                        <li><a onClick={(e) => { openLink(e, '/cheese') }}>M.S SEAMLESS CHEESE PIPES</a></li>
                        <li><a onClick={(e) => { openLink(e, '/bobbin') }}> M.S SEAMLESS BOBBIN COPS PIPES</a></li>
                        <li><a onClick={(e) => { openLink(e, '/tubes') }}>CHEESE TUBES/PIPES</a></li>
                        <li><a onClick={(e) => { openLink(e, '/carbon') }}>C.S CARBON STEEL Seamless Tubes</a></li>
                      </ul>

                    </div>
                  </div>
                  <div className="single-widget services-list">
                    <div className="content">
                      {/* <ul> */}
                      {/* <li className="current-item"><a href="services-details.html">Maintenance Service</a></li>
                        <li><a href="services-details.html">Agricultural Process</a></li>
                        <li><a href="services-details.html">Material Engineering</a></li>
                        <li><a href="services-details.html">Industry Construction</a></li>
                        <li><a href="services-details.html">Chemical Research</a></li> */}

                      {/* <button>MOST POPULAR PRODUCTS</button> */}

                      {/* 
                        <li><NavLink to="/servicesdetails"> Maintenance Service</NavLink></li>
                        <li><NavLink to="/servicesdetails">Agricultural Process</NavLink></li>
                        <li><NavLink to="/servicesdetails"> Material Construction</NavLink></li>
                        <li><NavLink to="/servicesdetails">Chemical Research</NavLink></li> */}

                      {/* </ul> */}
                    </div>
                  </div>
                  {/* End Single Widget */}
                  {/* <div className="single-widget quick-contact text-light" style={{backgroundImage: 'url(assets/img/about/1.jpg)'}}>
                    <div className="content">
                      <h4>Need any help?</h4>
                      <i className="fas fa-headset" />
                      <h2>(012) 6679545</h2>
                    </div>
                  </div> */}
                  {/* End Single Widget */}
                  {/* Single Widget */}
                  {/* <div className="single-widget brochure">
                    <h4 className="widget-title">Brochure</h4>
                    <ul>
                      <li><a><i className="fas fa-file-pdf" /> Download Docs</a></li>
                      <li><a><i className="fas fa-file-word" /> Company details</a></li>
                    </ul>
                  </div> */}
                  {/* End Single Widget */}
                </div>
                <div className="col-lg-8 services-single-content">
                  <img src="assets/img/blog/tubes1.jpeg" alt="Thumb" />
                  <h2>Description</h2>
                  <p>
                    {/* We denounce with righteous indige nation and dislike men who are so beguiled and demo realized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue cannot foresee. These cases are perfectly simple and easy to distinguish. In a free hour, when our power of choice is untrammelled data structures manages data in technology. */}
                    We are Vadodara, Gujarat (India) based firm that provides best quality <b>MS Cheese Tubes</b>. These are used in open end motor and twisting machines. With the high temperature resistance, these tubes provide best protection. In addition to this, these pipes diminish the chances of accident due to shock. Moreover, we offer these tubes in various vibrant and bright color. The cylindrically shape of these <b>MS Cheese Tubes</b> make them easy to fit. Further, these are also shock resistance. With best grades of mild steel being used to manufacture this product, its corrosion resistance is guaranteed. All the tubes have round shape and seamless construction.                  </p>
                  {/* <div className="features">
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="icon">
                          <i className="flaticon-solar-panel" />
                        </div>
                        <div className="content">
                          <h4>Research &amp; Analyze</h4>
                          <p>
                            These cases are perfectly simple and easy to distinguish. In a free hour, when our power of choice is untrammelled.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="icon">
                          <i className="flaticon-tripod" />
                        </div>
                        <div className="content">
                          <h4>Sketch &amp; Optimizing</h4>
                          <p>
                            These cases are perfectly simple and easy to distinguish. In a free hour, when our power of choice is untrammelled.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* <p>
                    Nam libero tempore, cum soluta nobis est elig endi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repelle ndus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias. consequatur aut perferendis doloribus asperiores repellat. The wise man therefore always holds in these matters to this principle of selection: he rejects pleasures to secure other greater pleasures, or else he endures pains to avoid worse pains.  pleasures have to be repudiated and annoyances accepted. 
                  </p> */}
                  {/* <h2>Maintenance Service</h2> */}
                  {/* <ul>
                    <li>How will digital activities impact traditional manufacturing.</li>
                    <li>All these digital elements and projects aim to enhance .</li>
                    <li>I monitor my staff with software that takes screenshots.</li>
                    <li>Laoreet dolore magna niacin sodium glutimate aliquam hendrerit.</li>
                    <li>Minim veniam quis niacin sodium glutimate nostrud exerci dolor.</li>
                  </ul> */}
                  <br />
                  <br />
                  <div className="sidebar-item recent-post">
                    <div className="title">
                      <h5>Price and Quantity</h5>
                    </div>
                    <table border="1px solid black">
                      <tr>
                        <td>
                          <b>
                            Minimum Order <br /> Quantity
                          </b>
                        </td>
                        <td>
                          5000 Pieces
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>
                            Price
                          </b>
                        </td>
                        <td>
                          18 - 25 INR/Piece
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Price Range</b>
                        </td>
                        <td>
                          18.00-25.00 INR/Piece
                        </td>
                      </tr>

                    </table>
                    <br /> <br />
                    <div className="sidebar-item recent-post">
                      <div className="title">
                        <h5>Product Specification</h5>
                      </div>
                      <table border="1px solid black">
                        <tr>
                          <td>
                            <b>Application</b>
                          </td>
                          <td>
                            Construction, Architectural, Drill Pipes, Gas Pipes, Boiler Pipes, Fluid Pipes, Chemical Fertilizer Pipes, Oil Pipes, Hydraulic Pipes, Structure Pipes
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Material</b>
                          </td>
                          <td>
                            Mild Steel
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Type</b>
                          </td>
                          <td>
                            Seamless
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Surface Treatment</b>
                          </td>
                          <td>
                            Other
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Surface Finish</b>
                          </td>
                          <td>
                            Other
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>
                              Technique
                            </b>
                          </td>
                          <td>
                            Cold Drawn
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Standard</b>
                          </td>
                          <td>
                            ASTM
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <b>Product Type</b>
                          </td>
                          <td>
                            Seamless steel Tubes
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Shape</b>
                          </td>
                          <td>
                            Round
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Section Shape</b>
                          </td>
                          <td>
                            Round
                          </td>
                        </tr>


                        <tr>
                          <td>
                            <b>Alloy or not</b>
                          </td>
                          <td>
                            Is Alloy
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Grade</b>
                          </td>
                          <td>
                            ASTM 106 GR-B
                          </td>
                        </tr>

                      </table>
                    </div>
                    <br /><br />
                    <div className="sidebar-item category">
                      <div className="title">
                        <h5>Trade Information</h5>
                      </div>
                      <div className="sidebar-info">
                        <table border="1px solid black">
                          <tr>
                            <td>
                              <b>Payment Terms</b>
                            </td>
                            <td>
                              Cheque
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Sample Policy</b>
                            </td>
                            <td>
                              Contact us for more information on sample policy
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Main Domestic Market</b>
                            </td>
                            <td>
                              All India
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Supply Capacity</b>
                            </td>
                            <td>
                              As per Requirement
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Main Export Markets</b>
                            </td>
                            <td>
                              Africa, Middle East, Western Europe, Eastern Europe, South America, North America, Central America, Australia, Asia
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>
                                Certifications
                              </b>
                            </td>
                            <td>
                              ISO 9001:2015
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <Enquiryform />
          <Footer />
        </div>
      </React.Fragment>
    )
  }
}

