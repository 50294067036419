import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Header from './../Header/Header'
import Footer from './../Footer/Footer'

export default class Gallery extends Component {
    componentDidMount() {
        document.title = "Patson Gallery"
    }
    render() {
        return (
            <React.Fragment>
                <Header />
                <div className="breadcrumb-area gradient-bg bg-cover shadow dark text-light text-center" style={{ backgroundImage: 'url(assets/img/banner/3.jpg)' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                <h1>Gallery</h1>
                                <ul className="breadcrumb">
                                    {/* <li><a><i className="fas fa-home" /> Home</a></li> */}
                                    <li><NavLink to="/">Home</NavLink></li>

                                    <li className="active">Gallery</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="gallery-area default-padding">
                    <div className="container">
                        <div className='row'>
                            <div className="col-md-6 gallery-content">
                                <video width={'600px'} height={'600px'}>
                                    <source src="./assets/img/blog/ok.MP4" type="video/mp4"></source>
                                </video>
                            </div>
                            <div className="col-md-6 gallery-content">
                                <video width={'600px'} height={'600px'}>
                                    <source src="./assets/img/blog/ok.MP4" type="video/mp4"></source>
                                </video>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 gallery-content">
                                <div className="magnific-mix-gallery masonary">
                                    <div id="portfolio-grid" className="gallery-items colums-2">
                                        {/* <!-- Single Item --> */}
                                        <div className="pf-item">
                                            <div className="overlay">
                                                <img src="assets/img/blog/11.jpg" alt="thumb" />
                                                <div className="content">
                                                    <div className="title">
                                                        <span>Workshop</span>
                                                        
                                                    </div>
                                                    <a ><i className="fas fa-arrow-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- End Single Item --> */}
                                        {/* <!-- Single Item --> */}
                                        <div className="pf-item">
                                            <div className="overlay">
                                                <img src="assets/img/blog/28.jpg" alt="thumb" width='600px' height='600px' />
                                                <div className="content">
                                                    <div className="title">
                                                        <span>Workshop</span>
                                                        {/* <h5><a >Merger & Acquisition</a></h5> */}
                                                    </div>
                                                    <a ><i className="fas fa-arrow-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- End Single Item --> */}
                                        {/* <!-- Single Item --> */}
                                        <div className="pf-item">
                                            <div className="overlay">
                                                <img src="assets/img/blog/66.JPG" alt="thumb" width={'600px'} height={'600px'} />
                                                <div className="content">
                                                    <div className="title">
                                                        <span>Furness</span>
                                                        {/* <h5><a >Assets For Technology</a></h5> */}
                                                    </div>
                                                    <a ><i className="fas fa-arrow-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- End Single Item --> */}
                                        {/* <!-- Single Item --> */}
                                        <div className="pf-item">
                                            <div className="overlay">
                                                <img src="assets/img/blog/55.JPG" alt="thumb" height={'600px'} width={'600px'} />
                                                <div className="content">
                                                    <div className="title">
                                                        <span>Seamless Pipes</span>
                                                        {/* <h5><a >Business Matching</a></h5> */}
                                                    </div>
                                                    <a ><i className="fas fa-arrow-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- End Single Item --> */}
                                        {/* <!-- Single Item --> */}
                                        <div className="pf-item">
                                            <div className="overlay">
                                                <img src="assets/img/blog/29.jpg" alt="thumb" width={'600px'} height={'600px'} />
                                                <div className="content">
                                                    <div className="title">
                                                        <span>Pipes</span>
                                                        {/* <h5><a >Startup Funding</a></h5> */}
                                                    </div>
                                                    <a ><i className="fas fa-arrow-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- Single Item --> */}
                                        <div className="pf-item">
                                            <div className="overlay">
                                                <img src="assets/img/CERTIFICATE-1.png" alt="thumb" width={'600px'} height={'600px'} />
                                                <div className="content">
                                                    <div className="title">
                                                        <span>Certificate</span>
                                                        {/* <h5><a >Startup Funding</a></h5> */}
                                                    </div>
                                                    <a ><i className="fas fa-arrow-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}
