import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Header from './../Header/Header'
import Header1 from './../Header/Header1'
import Footer from './../Footer/Footer'
import Product from './Product'
// import '/public/assets/style.css';

export default class Main extends Component {
  componentDidMount() {
    document.title = " Patson Pipes"
  }
  render() {
    console.log('main==========>')
    return (
      <React.Fragment>
        <Header />
        {/* Start Banner 
    ============================================= */}
        <div className="banner-area text-large">
          <div id="bootcarousel" className="carousel slide carousel-fade animate_text" data-ride="carousel">
            {/* Wrapper for slides */}
            <div className="carousel-inner">
              <div className="carousel-item bg-cover active" style={{ backgroundImage: 'url(assets/img/banner/27.JPG)' }}>
                <div className="box-table">
                  <div className="box-cell">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="content">
                            <div className="shape animated slideInLeft" />
                            <h3 data-animation="animated slideInUp">Seamless Pipes and Tubes</h3>
                            <h2 data-animation="animated slideInDown">To Support &amp; Piping System Properly</h2>
                            <div className="slider-button">
                              <NavLink data-animation="animated slideInUp" className="btn btn-theme effect btn-md" to="/gallery">View More</NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item bg-cover" style={{ backgroundImage: 'url(assets/img/banner/29.jpeg)' }}>
                <div className="box-table">
                  <div className="box-cell">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="content">
                            <div className="shape animated slideInLeft" />
                            <h3 data-animation="animated slideInUp">Helping Businesses</h3>
                            <h2 data-animation="animated slideInRight">To Empower Refineries &amp; Industries</h2>
                            <div className="slider-button">
                              <NavLink data-animation="animated slideInUp" className="btn btn-theme effect btn-md" to="/gallery">View More</NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Wrapper for slides */}
            {/* Left and right controls */}
            <a className="left carousel-control theme" href="#bootcarousel" data-slide="prev">
              <i className="fa fa-angle-left" />
              <span className="sr-only">Previous</span>
            </a>
            <a className="right carousel-control theme" href="#bootcarousel" data-slide="next">
              <i className="fa fa-angle-right" />
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>
        {/* End Banner */}
        {/* Start Our About
    ============================================= */}
        <div className="about-area ver-two overflow-hidden shape-box default-padding-top">
          {/* Fixed Shape */}
          <div className="fixed-shape">
            <img src="assets/img/shape/1.png" alt="Shape" />
          </div>
          {/* End Fixed Shape */}
          <div className="container">
            <div className="row align-center">
              <div className="col-lg-5 thumb-box">
                <div className="thumb">
                  <img src="assets/img/CERTIFICATE-1.png" alt="Thumb" />
                </div>
              </div>
              <div className="col-lg-7 info">
                <h2 className="text-opacity">PATSON</h2>
                <h2>INTRODUCTION</h2>
                <p>
                  Patson Pipes and Tubes is a leading manufacturing company producing M.S. / C.S. / Seamless Pipes & Tubes.
                  <br />
                  Our Company is strategically located at National Highway No. 8 (Delhi Mumbai Road), near Baroda, Gujarat. We have airport and railway station in very close proximity, making us easily accessible.
                  <br />
                  We Started our journey in 1996 in humble way. Today with factory area of more than 1,50,000 sq. ft., we have grown into a huge conglomerate. Patson Pipes and Tubes boasts of its superior technical knowledge and state of art processing facilities. We have also obtained prestigious "IBR" Certification. In this changing global scenario and touching domestic market, quality with competitive pricing is order of the day.
                  <br />
                  We are also making Cheese Pipes. We have a substantial domestic market demand and since 2005, we have also started our international marketing.
                  <br />
                  Due to rich technical base and advanced processing equipments, the company has been taking the leading place in Drawn Quality Pipes/Tubes field in India. In the global scenario, the Patson Pipes and Tubes has new features to develop the changes of world economy and opportunity to achieve goals.

                </p>

                <NavLink className="btn btn-theme effect btn-md" to="/about" >Know More</NavLink>
              </div>
            </div>
          </div>
          {/* Shape */}
          <div className="shape-bg">
            <img src="assets/img/shape.png" alt="Shape" />
          </div>
          {/* End Shape */}
        </div>
        {/* End Our About */}
        {/* Start Services 
    ============================================= */}
        <div className="services-area carousel-shadow with-thumb bg-gray default-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <div className="site-heading text-center">
                  <h2>Our Motto</h2>
                  <p>
                    Our Motto is delivering Quality Products in a stipulated time frame by treating each and every customer with highest regards and dignity.
                    <br />
                    We also instil with high moral values in our teams by contact resolving in nurturing talent and fostering team work.
                  </p>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <div className="site-heading text-center">
                    <h2>Our Vision</h2>
                    <p>
                      We constantly aspire to reach higher echo lens in demonstrably ethical standards, respecting our environment responsibilities and providing safe and conductive working place for employees.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <div className="site-heading text-center">
                    <h2>Our Goal</h2>
                    <p>
                      Our Goal is to become the supplier of choice by delivering quality products and services and creating superior value propositions for our customers.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <div className="site-heading text-center">
                    <h2>Our Attempt</h2>
                    <p>
                      Our Attempt is developing state of art solution in technology, process and end products in using NGO age and innovative approach.
                    </p>
                  </div>
                </div>
              </div>
            </div>


            {/* <div className="row">
              <div className="col-lg-12">
                <div className="services-items services-carousel owl-carousel owl-theme">
                  {/* Single Item */}
                  {/* <div className="item">
                    <div className="thumb">
                      <img src="assets/img/services/1.jpg" alt="Thumb" />
                    </div>
                    <div className="info">
                      <i className="flaticon-milling-machine" />
                      <h4>
                        {/* <NavLink>Construction &amp; Engineering</a> */}
                        {/* <NavLink to="/servicesdetails"> Construction &amp;Engineering</NavLink>
                      </h4>
                      <p>
                        &amp;Direct enough off others say eldest may exeter she. Possible all ignorant supplied get settling marriage recurred. Past add size game.
                      </p>
                      <div className="button"> */}
                        {/* <a href="services-details.html">Read More  */}
                        {/* <NavLink to="/servicesdetails">Read more
                          <i className="fas fa-plus" />
                        </NavLink>
                      </div>
                    </div>
                  </div>  */}
                  {/* End Single Item */}
                  {/* Single Item */}
                  {/* <div className="item">
                    <div className="thumb">
                      <img src="assets/img/services/2.jpg" alt="Thumb" />
                    </div>
                    <div className="info">
                      <i className="flaticon-wind-energy" />
                      <h4> */}
                        {/* <a href="services-details.html">Power &amp; Energy</a> */}
                        {/* <NavLink to="/servicesdetails">Power &amp; Energy</NavLink>
                      </h4>
                      <p>
                        Direct enough off others say eldest may exeter she. Possible all ignorant supplied get settling marriage recurred. Past add size game.
                      </p>
                      <div className="button text-right">
                        <NavLink to="/servicesdetails">Read More <i className="fas fa-plus" /></NavLink>
                      </div>
                    </div>
                  </div> */}
                  {/* End Single Item */}
                  {/* Single Item */}
                  {/* <div className="item">
                    <div className="thumb">
                      <img src="assets/img/services/3.jpg" alt="Thumb" />
                    </div>
                    <div className="info">
                      <i className="flaticon-house" />
                      <h4> */}
                        {/* <a href="services-details.html">Mechanical Engineering</a> */}
                        {/* <NavLink to="/servicesdetails"> Mechanical Engineering</NavLink>
                      </h4>
                      <p>
                        Direct enough off others say eldest may exeter she. Possible all ignorant supplied get settling marriage recurred. Past add size game.
                      </p>
                      <div className="button text-right">
                        <NavLink to="/servicesdetails">Read More <i className="fas fa-plus" /></NavLink>
                      </div>
                    </div>
                  </div> */}
                  {/* End Single Item */}
                  {/* Single Item */}
                  {/* <div className="item">
                    <div className="thumb">
                      <img src="assets/img/services/4.jpg" alt="Thumb" />
                    </div>
                    <div className="info">
                      <i className="flaticon-building" />
                      <h4>
                        <NavLink to="/servicesdetails">Ship Building Insudtry</NavLink>
                      </h4>
                      <p>
                        Direct enough off others say eldest may exeter she. Possible all ignorant supplied get settling marriage recurred. Past add size game.
                      </p>
                      <div className="button text-right">
                        <NavLink to="/servicesdetails">Read More <i className="fas fa-plus" /></NavLink>
                      </div>
                    </div>
                  </div> */}
                  {/* End Single Item */}
                {/* </div> */}
              {/* </div> */}
            {/* </div>  */}
          </div>
        </div>
        {/* End Services */}
        {/* Start Work Porccess 
    ============================================= */}
        <Product />
        {/* End Work Porccess */}
        {/* Start Why Chose Us 
    ============================================= */}
        <div className="why-us-area default-padding shadow bg-dark text-light">
          {/* Fixed Thumb */}
          <div className="fixed-thumb bg-cover" style={{ backgroundImage: 'url(assets/img/banner/9.jpg)' }} />
          {/* End Fixed Thumb */}
          <div className="container">
            <div className="row align-center">
              <div className="col-lg-5 offset-lg-3 left-info">
                <div className="content">

                  <h2>Usage and Application of Seamless Pipes and Tubes</h2>

                </div>
              </div>
              <div className="col-lg-4 info">
                <div className="content">
                  <ul>
                    <li>
                      <h6>Woven Sack Industries</h6>
                    </li>
                    <li>
                      <h6>Boiler</h6>
                    </li>
                    <li>
                      <h6>Heat Exchanger</h6>
                    </li>
                    <li>
                      <h6>Condenset and Super Heater</h6>
                    </li>
                    <li>
                      <h6>Mechanical</h6>
                    </li>
                    <li>
                      <h6>Structural and General Engineering</h6>
                    </li>
                    <li>
                      <h6>Chemical and Pharmaceutical Industries</h6>
                    </li>
                    <li>
                      <h6>Process Industries and Ceramic Industries</h6>
                    </li>
                    <li>
                      <h6>Bearing industry and Hydraulic Systems</h6>
                    </li>
                    <li>
                      <h6>Oil and Gas Industries</h6>
                    </li>
                    <li>
                      <h6>Automative Industries</h6>
                    </li>
                    <li>
                      <h6>Power Plants</h6>
                    </li>
                    <li>
                      <h6>Gas Lines</h6>
                    </li>
                    <li>
                      <h6>Steel Plants</h6>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Why Chose Us */}

        {/* Start Testimonials Area 
    ============================================= */}
        {/* <div className="testimonials-area default-padding">
          <div className="container">
            <div className="testimonial-items">
              <div className="row align-center">
                <div className="col-lg-5 title text-center">
                  <h1 style={{ backgroundImage: 'url(assets/img/services/11.jpg)' }}>25+</h1>
                  <h2>Years Of Experience</h2>
                </div>
                <div className="col-lg-7 testimonial-box">
                  <OwlCarousel className="testimonial-content testimonials-carousel owl-carousel owl-theme" items={1} margin={8} autoplay={true} >
                    <div className="item">
                      <div className="content">
                        <div className="rating">
                          <i className="fas fa-star" />
                          <i className="fas fa-star" />
                          <i className="fas fa-star" />
                          <i className="fas fa-star" />
                          <i className="fas fa-star-half-alt" />
                        </div>
                        <p>
                          I feel very happy and be proud to connect with absolute industry. Pure presume this is a very productive and perfect professional industry. I wish very good luck &amp; success for this industry. Through it examine express game.
                        </p>
                      </div>
                      <div className="provider">
                        <div className="thumb">
                          <img src="assets/img/team/7.jpg" alt="Thumb" />
                        </div>
                        <div className="info">
                          <h5>Jones Adhor</h5>
                          <span>Architect</span>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="content">
                        <div className="rating">
                          <i className="fas fa-star" />
                          <i className="fas fa-star" />
                          <i className="fas fa-star" />
                          <i className="fas fa-star" />
                          <i className="fas fa-star-half-alt" />
                        </div>
                        <p>
                          I feel very happy and be proud to connect with absolute industry. Pure presume this is a very productive and perfect professional industry. I wish very good luck &amp; success for this industry. Through it examine express game.
                        </p>
                      </div>
                      <div className="provider">
                        <div className="thumb">
                          <img src="assets/img/team/7.jpg" alt="Thumb" />
                        </div>
                        <div className="info">
                          <h5>Jones Adhor</h5>
                          <span>Architect</span>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="content">
                        <div className="rating">
                          <i className="fas fa-star" />
                          <i className="fas fa-star" />
                          <i className="fas fa-star" />
                          <i className="fas fa-star" />
                          <i className="fas fa-star-half-alt" />
                        </div>
                        <p>
                          I feel very happy and be proud to connect with absolute industry. Pure presume this is a very productive and perfect professional industry. I wish very good luck &amp; success for this industry. Through it examine express game.
                        </p>
                      </div>
                      <div className="provider">
                        <div className="thumb">
                          <img src="assets/img/team/7.jpg" alt="Thumb" />
                        </div>
                        <div className="info">
                          <h5>Jones Adhor</h5>
                          <span>Architect</span>
                        </div>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* End Testimonials Area */}
        {/* Start Clients Area
    ============================================= */}

        {/* End Clients Area */}
        <Footer />
      </React.Fragment>
    );
  }
}
































