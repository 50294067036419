

import React, { Component } from 'react'
// import { NavLink } from 'react-router-dom'

export default class Enquiryform extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="contact-area default-padding-top">
                    <div className="">
                        <div className="contact-items">
                            <div className="row">
                                <div className="col-lg-12 left-item">
                                    <div className="content">
                                        <h2>Patson Pipes & Tubes</h2>
                                        <form method="POST" className="contact-form">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <h5>Describe Your BUYING Requirement </h5>
                                                        <input className="form-control" id="name" name="name" placeholder="I am interested in your products." type="text" />
                                                        <span className="alert-error" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <h5>Email</h5>
                                                        <input className="form-control" id="email" name="email" placeholder="Email*" type="email" />
                                                        <span className="alert-error" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <h5>Phone</h5>
                                                        <input className="form-control" id="phone" name="phone" placeholder="Phone" type="text" />
                                                        <span className="alert-error" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <button type="submit" name="submit" id="submit">
                                                        Send Inquiry <i className="fa fa-paper-plane" />
                                                    </button>
                                                </div>
                                            </div>
                                            <br />
                                            {/* Alert Message */}
                                            <div className="col-lg-12 alert-notification">
                                                <div id="message" className="alert-msg" />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

