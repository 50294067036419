

import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';

export default class App extends Component {

    render() {

        const openLink = (e,link) => {
            e.preventDefault();
            console.log(link);
            window.location=link
        }

        return (
            <div>
                {/* <div className="se-pre-con" /> */}
                {/* Start Header Top */}
                <div className="top-bar-area bg-dark text-light">
                    <div className="container">
                        <div className="row align-center">
                            <div className="col-lg-3 shape">
                                <ul className="social">
                                    <li className="facebook">
                                        <a><i className="fab fa-facebook-f" /></a>
                                    </li>
                                    <li className="twitter">
                                        <a><i className="fab fa-twitter" /></a>
                                    </li>
                                    <li className="linkedin">
                                        <a><i className="fab fa-linkedin-in" /></a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-9 info float-right text-right">
                                <div className="info box">
                                    <ul className="list">
                                        <li>
                                            <i className="fa fa-phone" />
                                            +91 9898585511
                                        </li>
                                        <li>
                                            <i className="fas fa-envelope-open" />
                                            <a href="mailto:patsonpipes@rediffmail.com">patsonpipes@rediffmail.com</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Header Top */}
                {/* Header */}
                <header id="home">
                    {/* Start Navigation */}
                    <nav className="navbar navbar-default navbar-sticky bootsnav">
                        {/* Start Top Search */}
                        <div className="top-search">
                            <div className="container">
                                <form method="get">
                                    <div className="input-group">
                                        <span className="input-group-addon"><i className="fa fa-search" /></span>
                                        <input type="text" className="form-control" placeholder="Search" />
                                        <span className="input-group-addon close-search"><i className="fa fa-times" /></span>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {/* End Top Search */}
                        <div className="container">
                            <div className="container">
                                {/* Start Atribute Navigation */}
                                <div className="attr-nav">
                                    <div className="call">
                                        <a href="tel:+91 8035063477">
                                            <i className="fas fa-phone" />
                                            <div className="contact">
                                                {/* <h4>+886 34467</h4> */}
                                                {/* <span>Get a code</span> */}
                                                <a onClick={(e)=>{openLink(e,'/contact')}}> GET A QUOTE</a>
                                                {/* <span ><h4>GET A QUOTE</h4></span> */}
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                {/* End Atribute Navigation */}
                                {/* Start Header Navigation */}
                                <div className="navbar-header">
                                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#navbar-menu">
                                        <i className="fa fa-bars" />
                                    </button>
                                    {/* <a className="navbar-brand" href="index.html"> */}
                                    <NavLink to="/" className="navbar-brand">
                                        <img src="assets/img/logo-yellow.png" className="logo" alt="Logo" />
                                        {/* </a> */}
                                    </NavLink>
                                </div>
                                {/* End Header Navigation */}
                                {/* Collect the nav links, forms, and other content for toggling */}
                                <div className="collapse navbar-collapse" id="navbar-menu">
                                    <ul className="nav navbar-nav navbar-right" data-in="#" data-out="#">
                                        <li><a href="#" onClick={(e)=>{openLink(e,'/')}} > Home </a></li>
                                        <li className="dropdown">
                                            <a className="dropdown-toggle" data-toggle="dropdown">Products</a>
                                            <ul className="dropdown-menu">
                                                {/* <li><NavLink to="/services">Services version one</NavLink></li> */}
                                                {/* <li><NavLink to="/servicesdetails">Services details</NavLink></li> */}
                                                <li><a href="#" onClick={(e)=>{openLink(e,'/seamlesspipes')}}>M.S Seamless Pipes</a></li>
                                                <li><a href="#" onClick={(e)=>{openLink(e,'/cheese')}}>M.S Seamless Cheese Pipes</a></li>
                                                <li><a href="#" onClick={(e)=>{openLink(e,'/bobbin')}}>M.S Seamless Bobbin  Cops Pipes</a></li>
                                                <li><a href="#" onClick={(e)=>{openLink(e,'/tubes')}}>Cheese Tubes/Pipes</a></li>
                                                <li><a href="#" onClick={(e)=>{openLink(e,'/carbon')}}>C.S Carbon Steel Seamless Pipes</a></li>
                                            </ul>
                                        </li>
                                        <li><a onClick={(e)=>{openLink(e,'/gallery')}}>gallery</a></li>
                                        <li><a onClick={(e)=>{openLink(e,'/team')}}>Team </a></li>
                                        <li><a onClick={(e)=>{openLink(e,'/about')}}>About</a></li>
                                        <li><a onClick={(e)=>{openLink(e,'/contact')}}>Contact us</a></li>
                                    </ul>
                                </div>
                                {/* /.navbar-collapse */}
                            </div>
                        </div>
                    </nav>
                    {/* End Navigation */}
                </header>
                {/* End Header */}
                {/* Start Banner  */}
            </div>
        );
    }
}