import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Header from './../Header/Header'
import Footer from './../Footer/Footer'
import Product from './Product'
export default class About extends Component {
  componentDidMount() {
    document.title = "Patson About"
  }
  render() {
    return (
      <React.Fragment>
        <Header />
        <div className="breadcrumb-area gradient-bg bg-cover shadow dark text-light text-center" style={{ backgroundImage: 'url(assets/img/about/10.jpg)' }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <h1>About Us</h1>
                <ul className="breadcrumb">
                  {/* <li><a><i className="fas fa-home" /> Home</a></li> */}
                  <li><NavLink to="/">Home</NavLink></li>
                  <li className="active">About Us</li>
                </ul>
              </div>
            </div>
          </div>
        </div>


        <div className="about-area overflow-hidden shape-box default-padding">
          <div className="container">
            <div className="about-box">
              <div className="row align-center">
                <div className="col-lg-6 thumb-left">
                  <div className="thumb">
                    <img className="item-mousemove" src="assets/img/CERTIFICATE-1.png" alt="Thumb" />
                    <div className="experiecne">
                      <h2>25+</h2>
                      <h5>Years of Work Experience</h5>
                    </div>
                    
                  </div>
                </div>
                <div className="col-lg-6 info">
                  <h2> Company Profile</h2>
                  <p>

                    Patson Pipes & Tubes are of exceedingly major importance because they are used in Industrial Piping system where is the pressure required. Patson Pipes & Tubes provides assurance to  the customers that the type of Pipes and Tubes it deals in are made to perfection and dimensioned accurately to serve for a longer duration of time and prevent is  leakage. Settled in the very famous city of Gujarat (India) that is considered as the cultural city of this Indian state, Vadodara from the last 17 years, we have established our distinct  identity in both, national and international markets.                      </p>

                  <div className="bottom">
                    <div className="call">
                      <div className="icon">
                        <NavLink to="tel:+91 9898585511">
                          <i className="fal fa-phone-volume" />
                          <div className="contact">
                            <h4>+919898585511</h4>
                            <span>Call Us Anytime</span>
                          </div>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className='container'>
          <img src='assets/img/CERTIFICATE-1.png'></img>
        </div> */}


        <Product />
        {/* End Work Porccess */}
        {/* Start Clients Area
      ============================================= */}
        {/* <div className="clients-area default-padding bg-dark text-light">
                {/* Fixed Shape */}
        {/* <div className="fixed-shape">
                  <img src="assets/img/shape/1.png" alt="Shape" />
                </div> */}
        {/* End Fixed Shape */}
        {/* <div className="container">
                  <div className="clients-item-box">
                    <div className="row align-center">
                      <div className="col-lg-5 left-info">
                        <h2>Our Partners</h2>
                        <p>
                          Forming two comfort invited. Yet she income effect edward. Entire desire way design few. Mrs sentiments led solicitude estimating friendship fat. Meant those event 
                        </p>
                      </div>
                      <div className="col-lg-6 offset-lg-1 clients-box">
                        <div className="clients-items owl-carousel owl-theme text-center">
                          <div className="single-item">
                            <a><img src="assets/img/clients/01.png" alt="Clients" /></a>
                          </div>
                          <div className="single-item">
                            <a><img src="assets/img/clients/02.png" alt="Clients" /></a>
                          </div>
                          <div className="single-item">
                            <a><img src="assets/img/clients/03.png" alt="Clients" /></a>
                          </div>
                          <div className="single-item">
                            <a><img src="assets/img/clients/04.png" alt="Clients" /></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
        {/* </div> */}


        <div className="team-area default-padding bottom-less bg-gray">
          {/* End Fixed Shape  */}
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <div className="site-heading text-center">

                  <h2>Company Details</h2>
                  <table border="1px solid black" >
                    <tr>
                      <td><b>Nature of Business </b></td>
                      <td> Manufacturer, Supplier and Exporter</td>
                    </tr>

                    <tr>
                      <td><b>Year Of Establishment</b></td>
                      <td>1996</td>
                    </tr>

                    <tr>
                      <td><b>No. of Production Units</b></td>
                      <td>01</td>
                    </tr>

                    <tr>
                      <td><b>Warehousing Facility</b></td>
                      <td>Yes</td>
                    </tr>

                    <tr>
                      <td><b>Original Equipment Manufacturer</b></td>
                      <td>Yes</td>
                    </tr>

                    <tr>
                      <td><b>Production Type</b></td>
                      <td>Semi Automatic</td>
                    </tr>

                    <tr>
                      <td><b>No. of Designers</b></td>
                      <td>02</td>
                    </tr>

                    <tr>
                      <td><b>No. of Engineers</b></td>
                      <td>03</td>
                    </tr>

                    <tr>
                      <td><b>No. of Office Staff</b></td>
                      <td>07</td>
                    </tr>

                    <tr>
                      <td><b>No. of Workers</b></td>
                      <td>25</td>
                    </tr>

                    <tr>
                      <td><b>Monthly Production Capacity</b></td>
                      <td>100 ton (May extend as per customer requirements)</td>
                    </tr>

                    <tr>
                      <td><b>Niche market</b></td>
                      <td>India</td>
                    </tr>

                    <tr>
                      <td><b>Company Branches</b></td>
                      <td>01</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

          </div>
        </div>
        <Footer />
      </React.Fragment>
    )
  }
}

