import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Header from './../Header/Header'
import Footer from './../Footer/Footer'
export default class Contact extends Component {
  componentDidMount() {
    document.title = "Patson Contact"
  }
  render() {
    return (
      <React.Fragment>
        <Header />
        <div className="breadcrumb-area gradient-bg bg-cover shadow dark text-light text-center" style={{ backgroundImage: 'url(assets/img/banner/30.jpg)' }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <h1>Contact Us</h1>
                <ul className="breadcrumb">
                  {/* <li><a href="index-2.html"><i className="fas fa-home" /> Home</a></li> */}
                  <li><NavLink to="/">Home</NavLink></li>

                  <li className="active">Contact</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="contact-area default-padding-top">
          <div className="container">
            <div className="contact-items">
              <div className="row">
                <div className="col-lg-8 left-item">
                  <div className="content">
                    <h2>Send a message</h2>
                    <form action="mailto:patsonpipes@rediffmail.com" method="POST" className="contact-form">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <input className="form-control" id="name" name="name" placeholder="Name" type="text" />
                            <span className="alert-error" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input className="form-control" id="email" name="email" placeholder="Email*" type="email" />
                            <span className="alert-error" />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input className="form-control" id="phone" name="phone" placeholder="Phone" type="text" />
                            <span className="alert-error" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group comments">
                            <textarea className="form-control" id="comments" name="comments" placeholder="Tell Us About Project *" defaultValue={""} />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <button type="submit" name="submit" id="submit">
                            Send Message 
                            {/* <i className="fa fa-paper-plane" /> */}
                          </button>
                        </div>
                      </div>
                      {/* Alert Message */}
                      <div className="col-lg-12 alert-notification">
                        <div id="message" className="alert-msg" />
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-lg-4 right-item">
                  {/* Single Item */}
                  <div className="item">
                    <div className="icon">
                      <i className="fas fa-map-marked-alt" />
                    </div>
                    <div className="info">
                      <h4>Location</h4>
                      <p>
                        Plot No. 375, G.i.d.c. Estate, Por-ramangamdi,<br /> Vadodara - 391243, Gujarat, India
                      </p>
                    </div>
                  </div>
                  {/* End Single Item */}
                  {/* Single Item */}
                  <div className="item">
                    <div className="icon">
                      <i className="fas fa-phone" />
                    </div>
                    <div className="info">
                      <h4>Make a Call</h4>
                      <p>
                        <a href="tel:+1234567890">+91 9898585511</a> <br /> <a href="tel:+1234567890">+91 0265 2830752</a> <br /> <a href="tel:+1234567890">+91 0265 2830852</a>
                      </p>
                    </div>
                  </div>
                  {/* End Single Item */}
                  {/* Single Item */}
                  <div className="item">
                    <div className="icon">
                      <i className="fas fa-envelope" />
                    </div>
                    <div className="info">
                      <h4>Send a Mail</h4>
                      <p>
                        <a href="mailto:patsonpipes@rediffmai.com">patsonpipes@rediffmail.com</a> <br /> <a href="mailto:patsonpipes@yahoo.co.in">patsonpipes@yahoo.co.in</a>
                      </p>
                    </div>
                  </div>
                  {/* End Single Item */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="maps-area" style={{ margin: "130px" }}>
          <div className="google-maps">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3695.9567134630925!2d73.16813171398809!3d22.127626054734588!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395fc221eaaaaaab%3A0xfe27eadb4ef58192!2sPATSON%20PIPES%20%26%20TUBES!5e0!3m2!1sen!2sin!4v1643780649400!5m2!1sen!2sin" loading="lazy" />
          </div>
        </div>
        <Footer />
      </React.Fragment>
    )
  }
}
