import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';

export default class App extends Component {
  render() {
      return (
    <div>

          {/* Start Footer 
    ============================================= */}
          <footer className="bg-dark text-light">
            <div className="container">
              <div className="f-items default-padding">
                <div className="row">
                  {/* Single Item */}
                  <div className="col-lg-4 col-md-6 single-item">
                    <div className="f-item contact">
                      <h4 className="widget-title">Contact Us</h4>
                      <p>
                      Plot No. 375, G.i.d.c. Estate, Por-ramangamdi, <br />  Vadodara - 391243, Gujarat, India
                      </p>
                      <ul>
                        <li>
                          <i className="fas fa-phone"  />
                          Phone: (0265) 2964455
                        </li>
                        <li>
                          <i className="fas fa-phone"  />
                          R.A. Patel: 9898585511
                        </li>
                        <li>
                          <i className="fas fa-phone"  />
                          K. J. Patel: - 93772 24455
                        </li>
                        <li>
                          <i className="fas fa-envelope-open" />
                          <a href="mailto:patsonpipes@rediffmail.com">patsonpipes@rediffmail.com</a>
                          
                        </li>
                        <li>
                          <i className="fas fa-envelope-open" />
                          <a href="mailto:patsonpipes@yahoo.co.in">patsonpipes@yahoo.co.in</a>
                          
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* End Single Item */}
                  {/* Single Item */}
                  <div className="col-lg-2 col-md-6 single-item">
                    <div className="f-item link">
                      <h4 className="widget-title">Products</h4>
                      <ul>
                        <li>
                          {/* <a href="services-details.html">Oil &amp; Gas</a> */}
                          <NavLink to="/seamlesspipes">M.S. Seamless Pipes</NavLink>
                        </li>
                        <li>
                          {/* <a href="services-details.html">Chemical Research</a> */}
                          <NavLink to="/cheese">M.S. Seamless Cheese Pipes</NavLink>
                          
                        </li>
                        <li>
                          {/* <a href="services-details.html">Industrial</a> */}
                          <NavLink to="/bobbin">M.S. Seamless Bobbin Cops Pipes</NavLink>

                        </li>
                        <li>
                          {/* <a href="services-details.html">Construction</a> */}
                          <NavLink to="/tubes">Cheese Tubes/Pipes</NavLink>

                        </li>
                        <li>
                          {/* <a href="services-details.html">Energy</a> */}
                          <NavLink to="/carbon">C.S. Carbon Steel Seamless Tubes</NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* End Single Item */}
                  {/* Single Item */}
                  
                  {/* End Single Item */}
                  {/* Single Item */}
                  <div className="col-lg-4 col-md-6 single-item">
                    <div className="f-item opening-hours">
                      <h4 className="widget-title">Working Hours</h4>
                      <ul>
                        <li> 
                          <span>MONDAY</span>
                          <div className="float-right"> 09:00 AM - 07:00 PM </div>
                        </li>
                        <li> 
                          <span>TUESDAY</span>
                          <div className="float-right"> 09:00 AM - 07:00 PM </div>
                        </li>
                        <li> 
                          <span>WEDNESDAY</span>
                          <div className="float-right"> 09:00 AM - 07:00 PM </div>
                        </li>
                        <li> 
                          <span>THURSADY</span>
                          <div className="float-right"> 09:00 AM - 07:00 PM </div>
                        </li>
                        <li> 
                          <span>FRIDAY</span>
                          <div className="float-right"> 09:00 AM - 07:00 PM </div>
                        </li>
                        <li> 
                          <span>SATURDAY</span>
                          <div className="float-right"> 09:00 AM - 07:00 PM </div>
                        </li>
                        <li> 
                          <span>SUNDAY</span>
                          <div className="float-right closed">CLOSED</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* End Single Item */}
                </div>
              </div>
            </div>
            {/* Fixed Shape */}
            <div className="footer-bottom">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <p>© Copyright 2022. Designed and Developed by <a target="_blank" href="https://limbanisoftwares.com">Limbani Softwares</a></p>
                  </div>
                  <div className="col-lg-6 text-right">
                    {/* <ul>
                      <li>
                        <NavLink to="/contact"> Terms of user</NavLink>
                      </li>
                      <li>
                        <NavLink to="/contact">License</NavLink>
                      </li>
                      <li>
                        <NavLink to="/contact">Support</NavLink>
                      </li>
                    </ul> */}
                  </div>
                </div>
              </div>
            </div>
            {/* Start Footer Bottom */}
            <div className="fixed-shape">
              <img src="assets/img/shape/footer-shape.png" alt="Shape" />
            </div>
            {/* End Fixed Shape */}
          </footer>
          {/* End Footer */}
    </div>
  );
}
}