import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Header from './../Header/Header'
import Footer from './../Footer/Footer'

export default class Team extends Component {
  componentDidMount() {
    document.title = "Patson Team"
  }
  render() {
    return (
      <React.Fragment>
        <Header />
        <div className="breadcrumb-area gradient-bg bg-cover shadow dark text-light text-center" style={{ backgroundImage: 'url(assets/img/team/1.jpg)' }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <h1>Team Members</h1>
                <ul className="breadcrumb">
                  {/* <li><a href="index-2.html"><i className="fas fa-home" /> Home</a></li> */}
                  <li><NavLink to="/">Home</NavLink></li>
                  <li className="active">Team</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="team-area default-padding-top bottom-less bg-gray">
          {/* Fixed Shape  */}
          <div className="fixed-shape">
            <img src="assets/img/shape/4.svg" alt="Shape" />
          </div>
          {/* End Fixed Shape  */}
          {/* <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <div className="site-heading text-center">
                  {/* <h4>Meet our experts</h4> */}
                  {/* <h2>Meet our Experts</h2>
                  <p>
                    While mirth large of on front. Ye he greater related adapted proceed entered an. Through it examine express promise no. Past add size game cold girl off how old
                  </p>
                </div>
              </div>
            </div>
            <div className="team-items text-center">
              <div className="row mb-5"> */}
                {/* Single Item */}
                {/* <div className="col-lg-4 col-md-6 single-item">
                  <div className="item">
                    <div className="thumb">
                      <img src="assets/img/worker/1.jpg" alt="Thumb" />
                      <div className="info">
                        <h4>Jessica Jones</h4>
                        <span>Founder of Factry</span>
                      </div>
                      <div className="overlay text-light">
                        <h4>Jessica Jones</h4>
                        <p>
                          Btuff sight equal of my woody. Him children bringing goodness suitable she entirely put far daughter pushing point.
                        </p>
                        <ul>
                          <li className="facebook">
                            <a ><i className="fab fa-facebook-f" /></a>
                          </li>
                          <li className="twitter">
                            <a ><i className="fab fa-twitter" /></a>
                          </li>
                          <li className="instagram">
                            <a ><i className="fab fa-instagram" /></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* End Single Item */}
                {/* Single Item */}
                {/* <div className="col-lg-4 col-md-6 single-item">
                  <div className="item">
                    <div className="thumb">
                      <img src="assets/img/worker/2.jpg" alt="Thumb" />
                      <div className="info">
                        <h4>Munia Ankor</h4>
                        <span>Architect</span>
                      </div>
                      <div className="overlay text-light">
                        <h4>Munia Ankora</h4>
                        <p>
                          Btuff sight equal of my woody. Him children bringing goodness suitable she entirely put far daughter pushing point.
                        </p>
                        <ul>
                          <li className="facebook">
                            <a ><i className="fab fa-facebook-f" /></a>
                          </li>
                          <li className="twitter">
                            <a ><i className="fab fa-twitter" /></a>
                          </li>
                          <li className="instagram">
                            <a ><i className="fab fa-instagram" /></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* End Single Item */}
                {/* Single Item */}
                {/* <div className="col-lg-4 col-md-6 single-item">
                  <div className="item">
                    <div className="thumb">
                      <img src="assets/img/worker/3.jpg" alt="Thumb" />
                      <div className="info">
                        <h4>Ahmed Kamal</h4>
                        <span>Engineering Officer</span>
                      </div>
                      <div className="overlay text-light">
                        <h4>Ahmed Kamala</h4>
                        <p>
                          Btuff sight equal of my woody. Him children bringing goodness suitable she entirely put far daughter pushing point.
                        </p>
                        <ul>
                          <li className="facebook">
                            <a ><i className="fab fa-facebook-f" /></a>
                          </li>
                          <li className="twitter">
                            <a ><i className="fab fa-twitter" /></a>
                          </li>
                          <li className="instagram">
                            <a ><i className="fab fa-instagram" /></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* End Single Item */}
              {/* </div>
            </div>
          </div> */} 
          {/* Team member flow chart */}
          <div className='container'>
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <div className="site-heading text-center">
                  {/* <h4>Meet our experts</h4> */}
                  <h2>Flow Chart of our team</h2>
                  <img src='./assets/img/blog/team.jpg'></img>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    )
  }
}

