import React, { Component } from 'react'
import './App.css';

// import Header from "./Components/Header/Header"
// import Footer from "./Components/Footer/Footer"
import Main from "./Components/Main/Main"
import { Route, } from 'react-router-dom';
import { Switch } from 'react-router-dom'
// import Footer from "./Components/Footer/Footer"
// import Services from './Components/Services';
import Team from './Components/Main/Team';
import About from './Components/Main/About';
import Gallery from './Components/Main/Gallery';
import Contact from './Components/Main/Contact';
// import Services from './Components/Main/Services';
// import Servicesdetails from './Components/Main/Servicesdetails';
import Seamlesspipes from "./Components/Main/Seamlesspipes"
import Cheese from "./Components/Main/Cheese"
import Bobbin from "./Components/Main/Bobbin"
import Tubes from "./Components/Main/Tubes"
import Carbon from "./Components/Main/Carbon"

export default class App extends Component {
 
  render() {
    console.log('app=======>')
    return (
      <React.Fragment>
        {/* <Header />   */}
        {/* <Main /> */}
        <Switch>
          <Route exact path="/" component={Main} />
          <Route exact path="/team" component={Team} />
          <Route exact path="/about" component={About} />
          <Route exact path="/gallery" component={Gallery} />
          <Route exact path="/contact" component={Contact} />
          {/* <Route exact path="/services" component={Services} /> */}
          {/* <Route exact path="/servicesdetails" component={Servicesdetails} /> */}
          <Route exact path="/seamlesspipes" component={Seamlesspipes} />
          <Route exact path="/cheese" component={Cheese} />
          <Route exact path="/bobbin" component={Bobbin} />
          <Route exact path="/tubes" component={Tubes} />
          <Route exact path="/carbon" component={Carbon} />
        </Switch>
        {/* <Footer /> */}
      </React.Fragment>
    );
  }
}