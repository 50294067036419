
import React, { Component } from 'react'
import { Link } from 'react-router-dom';

export default class Product extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="work-process-area relative default-padding bottom-less bg-fixed" style={{ backgroundImage: 'url(assets/img/bg.png)' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                <div className="site-heading text-center">
                                    <h2>Our Products</h2>

                                </div>
                            </div>
                        </div>
                        <div className="work-pro-items">
                            <div className="row">
                                
                                <div className="col-lg-4 col-md-6 single-item">
                                    <Link to="/seamlesspipes">
                                        <div className="item">
                                            <div className="item-inner">
                                                <img src='assets/img/product/Mild-Steel-Seamless-Pipes.jpg' alt='product'/>
                                            </div>
                                            <h5 className='mt-5 text text-center'> M.S. Seamless Pipes</h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-lg-4 col-md-6 single-item">
                                    <Link to="/cheese">
                                        <div className="item">
                                            <div className="item-inner">
                                                <img src='assets/img/product/MS-Cheese-Tubes.jpg' alt='product'/>
                                            </div>
                                            <h5 className='mt-5 text text-center'> M.S. Seamless Cheese Pipes</h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-lg-4 col-md-6 single-item">
                                    <Link to="/tubes">
                                        <div className="item">
                                            <div className="item-inner">
                                                <img src='assets/img/product/Mild-Steel-Seamless-Cheese-Pipes.jpg' alt='product'/>
                                            </div>
                                            <h5 className='mt-5 text text-center'>Cheese Tubes and Pipes</h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-lg-4 col-md-6 single-item">
                                    <Link to="/bobbin">
                                        <div className="item">
                                            <div className="item-inner">
                                                <img src='assets/img/product/Mild-Steel-Seamless-Bobbin-Cops-Pipes.jpg' alt='product'/>
                                            </div>
                                            <h5 className='mt-5 text text-center'>M.S Seamless Bobbin and Cops Pipes</h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-lg-4 col-md-6 single-item">
                                    <Link to="/carbon">
                                        <div className="item">
                                            <div className="item-inner">
                                                <img src='assets/img/product/Carbon-Steel-Seamless-Tubes.jpg' alt='product'/>
                                            </div>
                                            <h5 className='mt-5 text text-center'>C.S. Carbon Steel Seamless Tubes</h5>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}